<table
    [dataSource]="dataSource"
    [matSortActive]="defaultSortField"
    [matSortDirection]="defaultSortDirection"
    class="u-w100p u-data-table-with-hover __dashboard-table"
    mat-table
    matSort
    matSortDisableClear
>
    <!-- Todo Definition -->
    <ng-container matColumnDef="todo">
        <th *matHeaderCellDef class="__cell __cell--todo" mat-header-cell>
            {{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.TODO' | translate }}
        </th>
        <td *matCellDef="let row" class="__cell __cell--todo" mat-cell>
            <div class="u-flex-row u-flex-align-items-center u-flex-justify-content-center u-color-error">
                <app-icon
                    *ngIf="!!row?.todosForRealEstateAgent?.length"
                    class="u-color-danger"
                    icon="error"
                    size="uiIconSize.Xm"
                ></app-icon>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="transaction">
        <th *matHeaderCellDef class="__cell __cell--transaction u-text-truncate" mat-header-cell mat-sort-header>
            {{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.TRANSACTION' | translate }}
        </th>
        <td *matCellDef="let row" class="__cell __cell--transaction" mat-cell>
            <div class="u-margin-top-xs u-margin-bottom-xs">
                <app-user-name [user]="row.user" class="u-font-weight-semi-bold"></app-user-name>
                <ng-container *ngIf="moveUtils.getMovers(row) as movers">
                    <div
                        *ngIf="!!movers.transferee?.user?.formattedMovingAddress; else empty"
                        [title]="movers.transferee?.user?.formattedMovingAddress"
                        class="u-margin-right"
                    >
                        {{ movers.transferee?.user?.formattedMovingAddress }}
                    </div>
                </ng-container>
            </div>
        </td>
    </ng-container>

    <app-table-column-created-on></app-table-column-created-on>

    <!-- Moving date Definition -->
    <ng-container matColumnDef="movingDate">
        <th *matHeaderCellDef class="__cell __cell--moving-date" mat-header-cell mat-sort-header>
            {{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.TRANSFER_DATE' | translate }}
        </th>
        <td *matCellDef="let row" class="__cell __cell--moving-date" mat-cell>
            <ng-container *ngIf="moveUtils.getMovers(row) as movers">
                <div [id]="'moving-date-cell-' + row._id">
                    {{
                        (view === moverRole.Transferee ? movers.transferee?.movingDate : movers.leaver?.movingDate) || row.movingDate
                            | formatDate
                    }}
                </div>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="otsConfirmationClusterLevel">
        <th *matHeaderCellDef class="__cell __cell--cluster-level u-padding-right-half" mat-header-cell>
            <div
                [hidePointer]="true"
                [placement]="tooltipPlacement"
                [template]="tooltipContent"
                appTooltip
                class="u-cursor-pointer u-position-relative u-margin-left-half"
            >
                {{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.SMOOVED_TOUCH_POINTS' | translate }}
            </div>
        </th>
        <td *matCellDef="let row" class="__cell __cell--cluster-level" mat-cell>
            <div class="u-w-fit-content u-position-relative u-margin-left-half">
                <app-power-bars
                    [current]="getLives(row.otsConfirmationClusterLevel)"
                    [scale]="powerBarsMap[getLives(row.otsConfirmationClusterLevel)]"
                    [total]="3"
                ></app-power-bars>
                <app-icon
                    *ngIf="row.energyOrdered"
                    [appearance]="uiIconAppearance.Default"
                    class="u-color-gray __check-icon"
                    icon="how_to_reg"
                    size="md"
                ></app-icon>
            </div>
        </td>
    </ng-container>

    <!-- Energy state Definition -->
    <ng-container matColumnDef="energyMetersState">
        <th
            *matHeaderCellDef
            class="__cell __cell--energy-meters"
            mat-header-cell
            mat-sort-header
            [disabled]="view !== moverRole.Transferee"
        >
            {{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.ENERGY_METERS' | translate }}
        </th>
        <td *matCellDef="let row" class="__cell __cell--energy-meters" mat-cell>
            <smvd-ui-energy-meter-state-pill
                (click)="openEnergyMeterInformation($event, row)"
                [id]="'energy-meter-state-pill-' + row._id"
                [meterState]="row?.track?.energyMeterReadings?.energyMetersState"
                [todo]="moveUtils.checkForTodo(row, TodoType.meterInfoNotComplete)"
            ></smvd-ui-energy-meter-state-pill>
        </td>
    </ng-container>

    <!-- Water state Definition -->
    <ng-container matColumnDef="waterMeterState">
        <th *matHeaderCellDef class="__cell __cell--energy-meters" mat-header-cell mat-sort-header>
            {{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.WATER_METER' | translate }}
        </th>
        <td *matCellDef="let row" class="__cell __cell--energy-meters" mat-cell>
            <smvd-ui-water-meter-state-pill
                (click)="openWaterMeterInformation($event, row)"
                [id]="'water-meter-state-pill-' + row._id"
                [meterState]="row?.track?.waterMeterReadings?.waterMetersState"
                [todo]="moveUtils.checkForTodo(row, TodoType.waterDocumentsMovingAddressByRealEstateAgent)"
            ></smvd-ui-water-meter-state-pill>
        </td>
    </ng-container>

    <!-- // TO-DO: Remove this after water meter state property is added -->
    <!-- Water status Definition -->
    <ng-container matColumnDef="waterStatus">
        <th *matHeaderCellDef class="__cell __cell--water-status" mat-header-cell>
            {{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.WATER_TRANSFER' | translate }}
        </th>
        <td *matCellDef="let row" class="__cell __cell--water-status" mat-cell>
            <ng-container *ngIf="!!row?.linkedMove?.realEstateGroupServices?.water" [ngSwitch]="moveSandbox.getWaterStatus(row.linkedMove)">
                <div *ngSwitchCase="serviceStatus.NoInfo" class="u-flex-row u-flex-align-items-center u-color-muted">
                    <app-icon class="u-margin-right-half" context="muted" icon="radio_button_unchecked" size="Xm"></app-icon>
                    <span class="u-text-truncate u-white-space-no-wrap u-hide-up-to-and-including-phone-landscape">
                        {{ 'MOVE.SERVICE_ACTIONS.NO_INFO' | translate }}
                    </span>
                </div>
                <div *ngSwitchCase="serviceStatus.Processing" class="u-flex-row u-flex-align-items-center u-color-muted">
                    <app-icon class="u-margin-right-half" context="muted" icon="check" size="Xm"></app-icon>
                    <span class="u-text-truncate u-white-space-no-wrap u-hide-up-to-and-including-phone-landscape">
                        {{ 'MOVE.SERVICE_ACTIONS.PROCESSING' | translate }}
                    </span>
                </div>
                <div *ngSwitchCase="serviceStatus.Processed" class="u-flex-row u-flex-align-items-center">
                    <app-icon appearance="" class="u-margin-right-half" context="primary" icon="check_circle" size="Xm"></app-icon>
                    <span class="u-text-truncate u-white-space-no-wrap u-hide-up-to-and-including-phone-landscape">
                        {{ 'MOVE.SERVICE_ACTIONS.COMPLETE' | translate }}
                    </span>
                </div>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="score">
        <th *matHeaderCellDef class="__cell __cell--score" mat-header-cell>
            {{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.SCORE.LABEL' | translate }}
        </th>
        <td *matCellDef="let row" class="__cell __cell--score" mat-cell>
            <app-nps-score [score]="row.surveys?.nps?.answers?.score?.value"></app-nps-score>
        </td>
    </ng-container>

    <ng-container matColumnDef="giftOptions">
        <th *matHeaderCellDef class="__cell __cell--gift-options" mat-header-cell></th>
        <td *matCellDef="let row" class="__cell __cell--gift-options" mat-cell>
            <div class="u-flex-row u-h100p u-flex-justify-content-space-around u-flex-align-items-center">
                <button
                    (click)="sendGift($event, row)"
                    app-button
                    appearance="link-reversed"
                    context="primary"
                    icon="card_giftcard"
                    iconSize="md"
                >
                    {{ 'MOVE.GIFT.SEND_GIFT_BOX.CTA' | translate }}
                </button>
                <button
                    (click)="confirmIgnoreGift($event, row)"
                    app-button
                    appearance="link-reversed"
                    context="primary"
                    icon="dangerous"
                    iconSize="md"
                >
                    {{ 'COMMON.IGNORE' | translate }}
                </button>
            </div>
        </td>
    </ng-container>

    <!-- Todo Mobile Definition -->
    <ng-container matColumnDef="todoMobile">
        <th *matHeaderCellDef class="__cell __cell--todo" mat-header-cell></th>
        <td *matCellDef="let row" class="__cell __cell--todo" mat-cell valign="top">
            <div class="__cell--todo-icon u-flex-row u-flex-align-items-center u-flex-justify-content-center u-color-error u-margin-left">
                <app-icon *ngIf="!!row?.todosForRealEstateAgent?.length" class="u-color-danger" icon="error" size="md"></app-icon>
            </div>
        </td>
    </ng-container>

    <!-- Transaction Mobile Definition -->
    <ng-container matColumnDef="transactionMobile">
        <th *matHeaderCellDef class="u-text-truncate u-padding-right-half u-padding-left-half" mat-header-cell></th>
        <td *matCellDef="let row" mat-cell>
            <div class="u-flex-column u-margin-left-half">
                <div class="u-margin-top u-margin-bottom-half">
                    <app-user-name [user]="row.user" class="u-font-weight-semi-bold"></app-user-name>
                    <ng-container *ngIf="moveUtils.getMovers(row) as movers">
                        <div
                            *ngIf="!!movers.transferee?.user?.formattedMovingAddress; else empty"
                            [title]="movers.transferee?.user?.formattedMovingAddress"
                        >
                            {{ movers.transferee?.user?.formattedMovingAddress }}
                        </div>
                    </ng-container>
                </div>
                <div class="u-flex u-flex-wrap u-margin-bottom">
                    <div class="__cell __cell--energy-meters u-margin-right-half">
                        <div class="u-color-gray-dark u-font-size-small u-margin-bottom-xs">
                            {{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.ENERGY_METERS' | translate }}
                        </div>
                        <smvd-ui-energy-meter-state-pill
                            (click)="openEnergyMeterInformation($event, row)"
                            [id]="'energy-meter-state-pill-' + row._id"
                            [meterState]="row?.track?.energyMeterReadings?.energyMetersState"
                            [todo]="moveUtils.checkForTodo(row, TodoType.meterInfoNotComplete)"
                        ></smvd-ui-energy-meter-state-pill>
                    </div>
                    <div class="__cell __cell--energy-meters">
                        <div class="u-color-gray-dark u-font-size-small u-margin-bottom-xs">
                            {{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.WATER_METER' | translate }}
                        </div>
                        <smvd-ui-water-meter-state-pill
                            (click)="openWaterMeterInformation($event, row)"
                            [id]="'water-meter-state-pill-' + row._id"
                            [meterState]="row?.track?.waterMeterReadings?.waterMetersState"
                            [todo]="moveUtils.checkForTodo(row, TodoType.waterDocumentsMovingAddressByRealEstateAgent)"
                        ></smvd-ui-water-meter-state-pill>
                    </div>
                </div>
            </div>
        </td>
    </ng-container>

    <!-- Detail Definition -->
    <app-table-column-detail-arrow></app-table-column-detail-arrow>

    <!-- Header and Row Declarations -->
    <tr *matHeaderRowDef="columns" class="u-hide-up-to-and-including-phone-landscape" mat-header-row></tr>
    <tr (click)="rowClick.emit(row)" *matRowDef="let row; columns: columns" mat-row></tr>
</table>
<mat-paginator
    [hidePageSize]="true"
    [length]="realEstateAgentDashboardSandbox.count$ | async"
    [pageSize]="defaultPageSize"
    [showFirstLastButtons]="true"
></mat-paginator>

<ng-template #empty>
    <div [title]="">-</div>
</ng-template>

<ng-template #tooltipContent>
    <div class="__stp-description u-color-text u-text-align-left u-w40vw">
        <h5 class="u-margin-bottom">{{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.TOOLTIPS.STP.TITLE' | translate }}</h5>
        <p class="u-color-muted u-margin-bottom">{{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.TOOLTIPS.STP.DESCRIPTION' | translate }}</p>
        <div class="u-flex-row u-flex-align-items-center u-margin-bottom">
            <app-power-bars
                *ngFor="let bar of bars; let i = index; trackBy: trackByIndex"
                [current]="maxPower - i"
                [scale]="powerBarsMap[maxPower - i]"
                [total]="maxPower"
                class="u-margin-right"
            ></app-power-bars>
            <p class="u-font-weight-bold">{{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.TOOLTIPS.STP.LIVES' | translate }}</p>
        </div>
        <div class="u-flex-row u-flex-align-items-center u-margin-bottom">
            <app-power-bars [current]="0" [scale]="powerBarsMap[0]" [total]="maxPower" class="u-margin-right"></app-power-bars>
            <p class="u-font-weight-bold">{{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.TOOLTIPS.STP.GAME_OVER' | translate }}</p>
        </div>
        <div class="u-flex-row u-flex-align-items-center u-margin-bottom">
            <app-icon [appearance]="uiIconAppearance.Default" class="u-color-gray u-margin-right" icon="how_to_reg" size="lg"></app-icon>
            <p class="u-font-weight-bold">{{ 'REAL_ESTATE_AGENT.DASHBOARD.TABLE.TOOLTIPS.STP.IS_CONFIRMED' | translate }}</p>
        </div>
    </div>
</ng-template>
