import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MoveDetailsActions } from '@app/move/classes/move-detail-actions.class';
import { MoverRole } from '@app/move/enums/mover-role.enum';
import { TodoFilter, TodoType, TodoTypeFilter } from '@app/move/enums/todo-type.enum';
import { moverRoleDefault } from '@app/real-estate-agent/components/dashboard-actions/dashboard-actions.constants';
import { DashboardActionsChangedEvent } from '@app/real-estate-agent/components/dashboard-actions/dashboard-actions.interfaces';
import { DashboardFilterComponent } from '@app/real-estate-agent/components/dashboard-filter/dashboard-filter.component';
import { OtherForm, todosForFilterMap } from '@app/real-estate-agent/components/dashboard-filter/dashboard-filter.constants';
import { DashboardMenu } from '@app/real-estate-agent/components/dashboard-menu/dashboard-menu.constants';
import { DashboardTableComponent } from '@app/real-estate-agent/components/dashboard-table/dashboard-table.component';
import { DashboardViews, TableViews } from '@app/real-estate-agent/components/dashboard-table/dashboard-table.constants';
import { defaultPageIndex } from '@app/real-estate-agent/constants/default-table.constants';
import { CreatedBy } from '@app/real-estate-agent/enums/created-by.enum';
import { RealEstateAgentFilter } from '@app/real-estate-agent/interfaces/real-estate-agent-filter.interface';
import { RealEstateAgentDashboardSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent-dashboard.sandbox';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { RealEstateAgentDashboardService } from '@app/real-estate-agent/services/real-estate-agent-dashboard.service';
import { RealEstateAgentModalsSandbox } from '@app/real-estate-agent/services/real-estate-agent-modals.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { RealEstateAgentMovesDataSource } from '@app/wizard/move/data-source/moves/real-estate-agent-moves.data-source';
import { HttpUtils, PaginationRequest } from '@smooved/core';
import { takeUntil } from 'rxjs/operators';
import { DashboardMenuService } from '../../components/dashboard-menu/dashboard-menu.service';

@Component({
    selector: 'app-dashboard-container',
    templateUrl: 'dashboard.container.html',
    styleUrls: ['./dashboard.container.scss'],
    providers: [RealEstateAgentDashboardService],
})
export class DashboardContainerComponent extends MoveDetailsActions implements OnInit {
    @ViewChild(DashboardTableComponent, { static: true }) public dashboardTable: DashboardTableComponent;
    @ViewChild(DashboardFilterComponent, { static: true }) public dashboardFilter: DashboardFilterComponent;

    public dataSource = new RealEstateAgentMovesDataSource(this.realEstateAgentDashboardSandbox);
    public moverRole = MoverRole;
    public activeRole: MoverRole = moverRoleDefault;
    public activeView: TableViews = moverRoleDefault;
    public showScoreFilter = true;
    public showMoverRole = true;

    private initialLoad = true;

    constructor(
        public readonly realEstateAgentDashboardSandbox: RealEstateAgentDashboardSandbox,
        private readonly activatedRoute: ActivatedRoute,
        private readonly realEstateAgentSandbox: RealEstateAgentSandbox,
        public readonly uiSandbox: AppUiSandbox,
        private readonly dashboardMenuService: DashboardMenuService,
        protected readonly realEstateAgentModalsSandbox: RealEstateAgentModalsSandbox,
        private readonly realEstateAgentDashboardService: RealEstateAgentDashboardService
    ) {
        super(realEstateAgentModalsSandbox);
    }

    public ngOnInit(): void {
        this.init();
    }

    public openDetail(id: string, moverRole?: MoverRole): void {
        moverRole === MoverRole.Leaver ? this.openLeaverDetail(id, this.onModalClose) : this.openTransfereeDetail(id, this.onModalClose);
    }

    public onActionsChange(event: DashboardActionsChangedEvent): void {
        this.handleDashboardMenuChange(event.role === MoverRole.Leaver ? DashboardMenu.Leavers : DashboardMenu.Transferees);
    }

    public onFilterChange(event: { filter: RealEstateAgentFilter; persist: boolean }): void {
        setTimeout(() => {
            const paginationRequest: PaginationRequest = {
                ...this.getPaginationRequest(),
                pageIndex: defaultPageIndex,
            };

            event.filter.moverRole = event.filter.moverRole || this.activeRole;
            event.filter.noGifts = this.activeView === DashboardViews.Gifts;
            event.filter.todos = this.mapTodosList(this.mapDashboardMenuTodoTypeFilter(this.dashboardMenuService.getActiveMenu()));

            this.realEstateAgentSandbox.setFilter(event.filter, event.persist);
            this.realEstateAgentDashboardSandbox.getMoves(paginationRequest, event.filter);
        }, 0);
    }

    public onPaginationChange(): void {
        const filter: RealEstateAgentFilter = {
            ...this.dashboardFilter.getFilterValue(),
            noGifts: this.activeView === DashboardViews.Gifts,
            todos: this.mapTodosList(this.mapDashboardMenuTodoTypeFilter(this.dashboardMenuService.getActiveMenu())),
        };

        filter.moverRole = filter.moverRole || this.activeRole;

        this.realEstateAgentSandbox.setFilter(filter, false);
        this.realEstateAgentDashboardSandbox.getMoves(this.getPaginationRequest(), filter);
    }

    public reload = (): void => {
        this.onPaginationChange();
        this.dashboardMenuService.reloadTodosCount();
    };

    private getPaginationRequest(): PaginationRequest {
        return HttpUtils.buildPaginationRequestObject(this.dashboardTable.getPaginationOptions());
    }

    private init(): void {
        const { id } = this.activatedRoute.snapshot.params;
        const { activeTab } = this.activatedRoute.snapshot.queryParams;
        if (id) this.openTransfereeDetail(id, null, activeTab);

        const { menu } = this.activatedRoute.snapshot.queryParams;
        if (menu) this.dashboardMenuService.setActiveMenu(menu as DashboardMenu);

        this.setInitialFilterValue();

        this.dashboardMenuService.activeMenu$.pipe(takeUntil(this.destroy$)).subscribe(this.handleDashboardMenuChange);
        this.realEstateAgentDashboardService.onReload$.pipe(takeUntil(this.destroy$)).subscribe(this.reload);
    }

    private mapTodosList(todo: TodoTypeFilter): TodoTypeFilter[] {
        if (!todo) return [];
        return todosForFilterMap[todo] || [todo];
    }

    private mapDashboardMenuTodoTypeFilter(item: DashboardMenu): TodoTypeFilter {
        switch (item) {
            case DashboardMenu.AllTodos:
                return TodoFilter.All;
            case DashboardMenu.EneryNotOrderedTodos:
                return TodoType.energyNotOrdered;
            case DashboardMenu.MetersNotCompletedTodos:
                return TodoType.meterInfoNotComplete;
            case DashboardMenu.WaterNotComletedTodos:
                return TodoType.waterDocumentsMovingAddressByRealEstateAgent;
            default:
                return;
        }
    }

    private mapActiveView(menu: DashboardMenu): TableViews {
        switch (menu) {
            case DashboardMenu.Gifts:
                return DashboardViews.Gifts;
            case DashboardMenu.Leavers:
                return MoverRole.Leaver;
            default:
                return MoverRole.Transferee;
        }
    }

    private setInitialFilterValue(): void {
        const { createdBy } = this.activatedRoute.snapshot.queryParams;
        this.dashboardFilter.updateForm(false, {
            [OtherForm.CreatedBy]: createdBy as CreatedBy,
        });
    }

    private setVisibleFilters(activeMenu: DashboardMenu): void {
        this.showScoreFilter = activeMenu === DashboardMenu.Gifts;
        this.showMoverRole = activeMenu === DashboardMenu.Gifts;
    }

    private handleDashboardMenuChange = (activeMenu: DashboardMenu): void => {
        this.activeRole = activeMenu === DashboardMenu.Leavers ? MoverRole.Leaver : MoverRole.Transferee;
        this.activeView = this.mapActiveView(activeMenu);
        this.setVisibleFilters(activeMenu);

        this.dashboardFilter.triggerChanges(!this.initialLoad);
        this.initialLoad = false;
    };

    private onModalClose = (): void => {
        if (this.movePatchedSubject.value) {
            this.reload();
            this.movePatchedSubject.next(false);
        }
    };
}
