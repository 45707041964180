<app-loading-container [loading]="loading">
    <form class="u-flex-column u-flex-align-items-start u-w100p u-margin-bottom-double" [formGroup]="form">
        <app-date-input
            *ngIf="showRelocationDate"
            [label]="movingDateLabelResource | translate"
            [placeholder]="'DATE.PLACEHOLDER' | translate"
            [minDate]="today"
            [hasMargin]="false"
            [hasMarginDouble]="true"
            [formControlName]="formControlNames.movingDate"
            width="auto"
        ></app-date-input>

        <app-check-input [option]="optionElectricity" [custom]="false" width="auto"
                         [formControl]="hasElectricity"></app-check-input>
        <ng-container [formGroup]="energyStopForm">
            <div class="u-form-section u-flex-column u-w100p u-margin-bottom-double" *ngIf="hasElectricity.value">
                <smvd-ui-text-input
                    id="currentSupplierElectricity"
                    [label]="prefixLabels + 'ENERGY_TRANSFER.CURRENT_SUPPLIER.LABEL' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="true"
                    [formControlName]="formControlNames.electricityCurrentSupplier"
                    data-testid="current-supplier-electricity"
                ></smvd-ui-text-input>

                <label class="u-margin-bottom-half">{{ 'ENERGY_TRANSFER.INVOICES.LABEL' | translate }}</label>
                <app-assets-input
                    width="100%"
                    [disabled]="form.disabled"
                    [assets]="electricityAssets"
                    [buttonLabel]="'ENERGY_TRANSFER.INVOICES.UPLOAD_CTA' | translate"
                    [buttonAppearance]="buttonAppearance.Stroked"
                    [context]="context.Secondary"
                    [minified]="false"
                    (change)="onFilesChange($event, energyType.Electricity)"
                    (remove)="removeItem($event, energyType.Electricity)"
                    (detail)="openDetail($event)"
                    [hasMarginDouble]="false"
                    [hasMargin]="false"
                    [buttonSize]="buttonSize.Sm"
                    [hint]="'ENERGY_TRANSFER.INVOICES.TIP' | translate"
                >
                </app-assets-input>
            </div>

            <app-check-input
                [option]="optionGas"
                class="u-margin-bottom"
                [hasMargin]="false"
                [hasMarginDouble]="false"
                [custom]="false"
                width="auto"
                [formControl]="hasGas"
            >
            </app-check-input>
            <div class="u-form-section u-flex-column u-w100p" *ngIf="hasGas.value">
                <app-check-input
                    *ngIf="hasElectricity.value"
                    [option]="optionSameSupplier"
                    [class.u-margin-bottom]="!hasSameSupplier.value"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    [custom]="false"
                    width="auto"
                    [formControlName]="formControlNames.hasSameSupplier"
                >
                </app-check-input>

                <ng-container *ngIf="!hasSameSupplier.value">
                    <smvd-ui-text-input
                        id="currentSupplierGas"
                        [label]="prefixLabels + 'ENERGY_TRANSFER.CURRENT_SUPPLIER.LABEL' | translate"
                        [hasMargin]="false"
                        [hasMarginDouble]="true"
                        [formControlName]="formControlNames.gasCurrentSupplier"
                    ></smvd-ui-text-input>

                    <label class="u-margin-bottom-half">{{ 'ENERGY_TRANSFER.INVOICES.LABEL' | translate }}</label>
                    <app-assets-input
                        width="100%"
                        [assets]="gasAssets"
                        [buttonLabel]="'ENERGY_TRANSFER.INVOICES.UPLOAD_CTA' | translate"
                        [context]="context.Secondary"
                        [buttonAppearance]="buttonAppearance.Stroked"
                        [buttonSize]="buttonSize.Sm"
                        [minified]="false"
                        (change)="onFilesChange($event, energyType.Gas)"
                        (remove)="removeItem($event, energyType.Gas)"
                        (detail)="openDetail($event)"
                        [hint]="'ENERGY_TRANSFER.INVOICES.TIP' | translate"
                    >
                    </app-assets-input>
                </ng-container>
            </div>
        </ng-container>
    </form>
</app-loading-container>
