import { cacheBusterHash } from '@smooved/core';

export const environment = {
    production: true,
    environmentName: 'production',
    initialData: false,
    appUri: 'https://app.smooved.be',
    serverUri: 'https://api.smooved.be',
    apiUri: 'https://api.smooved.be/api/v1',
    faqUri: 'https://smooved.be/faq',
    termsAndConditionsUri: {
        nl: 'https://www.smooved.be/algemene-voorwaarden/',
        fr: 'https://www.smooved.be/algemene-voorwaarden/',
        en: 'https://www.smooved.be/algemene-voorwaarden/',
    },
    privacyUri: {
        nl: 'https://www.smooved.be/privacy/',
        fr: 'https://www.smooved.be/privacy/',
        en: 'https://www.smooved.be/privacy/',
    },
    widgetUri: 'https://widgets.smooved.be',
    widgetBootstrapScript: 'https://widgets.smooved.be/bootstrap/v2.bootstrap.min.js',
    widgetBootstrapName: 'v2.bootstrap',
    services: {
        telecom: true,
        energy: true,
        water: true,
        insurance: true,
        logistics: true,
        post: true,
        iots: false,
    },
    agreementUri: 'https://energieovernamedocument.be',
    showTestWebhook: false,
    showBeamer: true,
    mockFormData: {
        mortgage: null,
        leadPassingWithoutLeaver: null,
        leadPassingWithLeaver: null,
        createRealEstateAgent: false,
    },
    externalInfluencerUri: 'https://influencers.smooved.be',
    telecomBannerUri: 'https://smooved-commercial.s3.eu-west-3.amazonaws.com/telecom/banner.png',
    telecomBannerFlashUri: 'https://smooved-commercial.s3.eu-west-3.amazonaws.com/telecom/banner-flash.png',
    telecomBannerFlash: false,
    cacheBuster: cacheBusterHash,
    houseMatchUri: 'https://app.housematch.be',
    houseMatchCompanyPageUri: 'https://app.housematch.be',
    insurancesPartner: 'Yago',
    packageInfoUri: {
        nl: 'https://smooved.be/pakketten/',
        fr: 'https://smooved.be/fr/formules/',
        en: 'https://smooved.be/pakketten/',
    },
    mailTo: {
        nl: 'verhuizen@smooved.be',
        fr: 'demenager@smooved.be',
        en: 'verhuizen@smooved.be',
    },
    featureToggle: {
        preFilledDocs: true,
    },
    postHogKey: 'phc_bW3j3GcBNkZZAXURp6Ri4MG8KYLFoMPRMJyccoO6V7b',
    postHogHost: 'https://eu.i.posthog.com',
    sentry: {
        dsn: 'https://f79407f89b3154243545e26c0cacead7@o4508018090967040.ingest.de.sentry.io/4508019315769424',
        tracesSampleRate: 0,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
    },
    imagesCdn: 'https://images.smooved.be',
};
