import { MoverRole } from '@app/move/enums/mover-role.enum';
import { TodoFilter, TodoType, TodoTypeFilter } from '@app/move/enums/todo-type.enum';
import { CreatedBy } from '@app/real-estate-agent/enums/created-by.enum';
import { EnumUtils } from '@app/shared/utils/enum-utils';
import { CreatedByFlowEnum, NumberUtils } from '@smooved/core';
import { DropdownInput } from '@smooved/ui';
import { NpsScoreCategoryValue } from 'projects/ui/src/lib/reviews/enums/nps-reviews-category.enum';

export const createdByDefault: CreatedBy = CreatedBy.Office;
export const createdByOnlyTodosDefault: CreatedBy = CreatedBy.Office;

export const createdByFlowOnlyTodosDefault: CreatedByFlowEnum = CreatedByFlowEnum.All;

export const moverRoleDefault = MoverRole.Transferee;

export enum OtherForm {
    CreatedBy = 'createdBy',
    MoverRole = 'moverRole',
    ScoreFilter = 'scoreFilter',
}

export const createdByOptions: DropdownInput<CreatedBy>[] = [
    {
        id: 'created-by-my-self',
        value: CreatedBy.Self,
        labelResource: 'REAL_ESTATE_AGENT.DASHBOARD.FILTER.CREATED_BY.SELF',
        name: 'created-by',
    },
    {
        id: 'created-by-my-office',
        value: CreatedBy.Office,
        labelResource: 'REAL_ESTATE_AGENT.DASHBOARD.FILTER.CREATED_BY.OFFICE',
        name: 'created-by',
    },
    {
        id: 'created-by-my-group',
        value: CreatedBy.Group,
        labelResource: 'REAL_ESTATE_AGENT.DASHBOARD.FILTER.CREATED_BY.GROUP',
        name: 'created-by',
    },
];

export const moverRoleOptions: DropdownInput<MoverRole>[] = [
    ...EnumUtils.keys(MoverRole).map((key) => ({
        id: key,
        name: 'moverRole',
        labelResource: `REAL_ESTATE_AGENT.DASHBOARD.FILTER.MOVER_ROLE.${key.toUpperCase()}`,
        value: MoverRole[key],
    })),
];

export const todosFactory = (list: string[]): DropdownInput<TodoTypeFilter>[] => {
    return list.map((key) => ({
        id: key,
        name: 'todoOptions',
        labelResource: `REAL_ESTATE_AGENT.DASHBOARD.FILTER.TODOS.${key.toUpperCase()}`,
        value: TodoType[key] || TodoFilter[key],
    }));
};

export const todosForFilterMap = {
    [TodoType.energyNotOrdered]: [TodoType.energyNotOrdered, TodoType.energyOptimizationNotOrdered],
};

export const scoreBucketsOptions: DropdownInput<number[]>[] = [
    {
        id: 'scoreBuckets-good',
        name: 'scoreBuckets',
        labelResource: `REAL_ESTATE_AGENT.DASHBOARD.FILTER.SCORE_BUCKETS.GREATER_THAN_AVERAGE`,
        value: NumberUtils.numbersList(NpsScoreCategoryValue.Good, NpsScoreCategoryValue.Excellent),
    },
    {
        id: 'scoreBuckets-average',
        name: 'scoreBuckets',
        labelResource: `REAL_ESTATE_AGENT.DASHBOARD.FILTER.SCORE_BUCKETS.GREATER_THAN_BAD`,
        value: NumberUtils.numbersList(NpsScoreCategoryValue.AverageBad, NpsScoreCategoryValue.Excellent),
    },
    {
        id: 'scoreBuckets-all',
        name: 'scoreBuckets',
        labelResource: `REAL_ESTATE_AGENT.DASHBOARD.FILTER.SCORE_BUCKETS.ALL`,
        value: NumberUtils.numbersList(NpsScoreCategoryValue.Low, NpsScoreCategoryValue.Excellent),
    },
];

export const scoreBucketsOptionDefault = scoreBucketsOptions[0].value;

export const otherFormDefaultValues = {
    [OtherForm.CreatedBy]: createdByDefault,
    [OtherForm.MoverRole]: moverRoleDefault,
    [OtherForm.ScoreFilter]: scoreBucketsOptionDefault,
};
