// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { cacheBusterHash } from '@smooved/core';

export const environment = {
    production: false,
    debug: true,
    environmentName: 'local',
    applicationName: 'HouseMatch',
    reCaptchaSiteKey: '6Lcisf4bAAAAAElwJZZF0d4tNqsKNugTJOLTMgWW',
    apiUri: 'http://localhost:3001/api/v1',
    externalInfluencerUri: 'https://localhost:5001',
    npsOgImageBaseUri: 'https://external-influencer-test.s3.eu-west-3.amazonaws.com/reviews/:filename',
    sitemapUri: 'https://smooved-public.s3.eu-west-3.amazonaws.com/external-influencer/sitemap.xml',
    smoovedUri: 'https://smooved.be',
    houseMatchUriWithoutWWW: 'https://housematch.be',
    houseMatchUri: 'https://www.housematch.be',
    houseMatchCompanyPageUri: 'http://localhost:4300',
    termsAndConditionsUrl: {
        nl: 'https://housematch.be/nl/algemene-voorwaarden/',
        fr: 'https://housematch.be/fr/conditions-generales/',
        en: 'https://housematch.be/nl/algemene-voorwaarden/',
    },
    privacyPolicyUrl: {
        nl: 'https://housematch.be/nl/privacy/',
        fr: 'https://housematch.be/fr/vie-privee/',
        en: 'https://housematch.be/nl/privacy/',
    },
    localStorageKey: 'smvd-match-local',
    cacheBuster: cacheBusterHash,
    socials: {
        facebook: 'https://www.facebook.com/HouseMatchBySmooved',
        instagram: 'https://www.instagram.com/housematchbysmooved/',
        youtube: 'https://www.youtube.com/channel/UCn-fNbuGaTUQMGXWodu3mKA',
        linkedIn: 'https://www.linkedin.com/company/housematch-by-smooved',
    },
    calendlyUrl:
        'https://calendly.com/laurens-vanpoucke/hm-copilot-60-min-:languageCode?embed_domain=smooved.be&embed_type=Inline&hide_landing_page_details=1&hide_event_type_details=1&hide_gdpr_banner=1&primary_color=ed6b57',
    featureToggle: {},
    sentry: {
        dsn: 'https://7802062874e3efb19538e5de78774d67@o4508018090967040.ingest.de.sentry.io/4508018409013328',
        tracesSampleRate: 0,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
    },
    imagesCdn: 'https://smooved-images-test.s3.eu-west-3.amazonaws.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
