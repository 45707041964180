<app-card-table-with-filter [hasPaddingTabletPortraitUp]="false" [hasPaddingTabletLandscapeUp]="false">
    <ng-container actions>
        <h5 class="u-margin-bottom-up-to-and-including-tablet-portrait">{{'MORTGAGE.OVERVIEW.TITLE' | translate}}</h5>
        <button app-button (click)="goToOrderMortgage()">{{'MORTGAGE.NEW' | translate }}</button>
    </ng-container>

    <ng-container filter>
        <app-dashboard-filter
            (filterChange)="onFilterChange($event)"
            [showCreatedBy]="false"
            [showScoreFilter]="false"
            [showMoverRole]="false"
        >
        </app-dashboard-filter>
    </ng-container>

    <ng-container table>
        <app-loading-container [loading]="dataSource.loading$ | async">
            <app-mortgage-table
                (pageEventChange)="onPaginationChange()"
                (sortChange)="onPaginationChange()"
                (rowClick)="onRowClick($event)"
                [dataSource]="dataSource"
                [count]="dataSource.count$ | async"
            ></app-mortgage-table>
        </app-loading-container>
    </ng-container>
</app-card-table-with-filter>
