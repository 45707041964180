export enum EnergyMetersState {
    Incomplete,
    Completed,
    Confirmed,
    Processed,
}

export enum WaterMetersState {
    Incomplete,
    Completed,
    Confirmed,
    Processed,
    NoTransfer,
}
