import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { RxjsComponent } from '@smooved/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-closable-modal-template',
    template: `
        <div cdkDrag [cdkDragRootElement]="dragRootElement">
            <button
                *ngIf="!hideCloseButton"
                app-button
                appearance="icon"
                icon="close"
                iconSize="md"
                class="__close"
                mat-dialog-close
                context="muted"
                data-testid="modal-close"
            ></button>
            <div mat-dialog-title [class.u-shadow-box]="scrolled$ | async" cdkDragHandle>
                <ng-content select="[modal-header]"></ng-content>
            </div>
            <mat-dialog-content (scroll)="onScroll($event)" class="u-flex-column">
                <div class="__closable-modal__main" [class]="mainClasses">
                    <ng-content></ng-content>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions>
                <ng-content select="app-button, [app-button], [modal-actions]"></ng-content>
            </mat-dialog-actions>
        </div>
    `,
    styleUrls: ['./closable-modal-template.component.scss'],
})
export class ClosableModalTemplateComponent extends RxjsComponent {
    @ViewChild(MatDialogContent) public content: MatDialogContent;

    @Input()
    @HostBinding('style.max-width')
    public maxWidth = '500px';

    @Input()
    @HostBinding('style.width')
    public width = 'auto';

    @Input()
    @HostBinding('style.minWidth')
    public minWidth = '0';

    @Input() mainClasses = '';

    @Input() hideCloseButton: false;

    public readonly scrolledSubject = new BehaviorSubject<boolean>(false);
    public readonly scrolled$ = this.scrolledSubject.asObservable().pipe(distinctUntilChanged(), takeUntil(this.destroy$));
    public readonly dragRootElement = '.cdk-overlay-pane';

    constructor(private dialogRef: MatDialogRef<any>) {
        super();
    }

    public close(dialogResult?: any): void {
        this.dialogRef.close(dialogResult);
    }

    public onScroll(event: MouseEvent): void {
        this.scrolledSubject.next(!!(event.target as Element).scrollTop);
    }
}
