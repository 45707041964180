import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DbUtils, IMAGES_CDN_INJECTION_TOKEN, StringUtils } from '@smooved/core';

@Pipe({
    name: 'realEstateGroupLogo',
    standalone: true,
    pure: true,
})
export class RealEstateGroupLogoPipe implements PipeTransform {
    private readonly url = `${this.imagesCdn}/real-estate-group/:id/logo-w100.webp`;

    constructor(@Inject(IMAGES_CDN_INJECTION_TOKEN) private readonly imagesCdn: string) {}

    transform(realEstateGroupId: string): string {
        const id = DbUtils.getStringId(realEstateGroupId);
        if (!id) return null;
        return StringUtils.parseUri(this.url, { id });
    }
}
