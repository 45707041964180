import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivationStageUtils, SimpleChangesUtils } from '@smooved/core';
import { UiIconAppearance, UiIconSize } from '../../../icon';
import { UiDirection, UiPlacement, UiSize } from '../../../ui.enums';
import { UiSandbox } from '../../../ui.sandbox';
import { HeaderBoxData } from './header-box-data';

@Component({
    selector: 'ui-header-box',
    templateUrl: './header-box.component.html',
    styleUrls: ['./header-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderBoxComponent implements OnChanges {
    @Input() public data: HeaderBoxData;
    @Input() public logo: string;

    public activated = true;
    public readonly uiDirection = UiDirection;
    public readonly uiSize = UiSize;
    public readonly iconAppearance = UiIconAppearance;
    public readonly iconSizes = UiIconSize;
    public readonly uiPlacement = UiPlacement;

    constructor(public readonly uiSandbox: UiSandbox) {}

    public ngOnChanges({ data }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(data) && !!data) {
            this.setAdditionalMeta(data.currentValue);
        }
    }

    private setAdditionalMeta(data: HeaderBoxData): void {
        this.activated = ActivationStageUtils.hasVerifiedBadge(data.meta.activationStage);
    }
}
